import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { useParams, Link } from 'react-router-dom'

import LogoutButton from '../components/logoutButton.js'

const CATEGORIES = gql `
    query getCategorys {
        categorys(sort: "Sorting:asc") {
            Name
            id,
            recipes(sort: "Food_Item_Name:asc") {
                id
                Food_Item_Name
                Main_Image {
                    url,formats
                }
            }
        }
    }
`

export default function Homepage() {
    const { id } = useParams()
    const { loading, error, data } = useQuery(CATEGORIES)

    if (loading) return (
        <div className="recipe-content">
            <div className="loader-container2">
                <div className="loader">
                <div className="sk-chase">
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                </div>
                </div>
            </div>
        </div>
    )

    if (error) return (<p>Error: Please contact the administrator</p>)

    if (data) return (
        <div id="w-node-_3e95dc38-7530-cb6b-56cb-6dba6d772cd9-738541ad" className="layout__contentframe">
        {data.categorys.map(category => (
        <div key={category.id} id={category.id} className="recipe-category">
            <div className="recipe-category__title-tag">{category.Name}</div>
            <div className="recipe-content__grid">
            {category.recipes.map (recipe => {
              return (
                <Link key={recipe.id} to={`/recipe/${recipe.id}`} className="recipe-category__recipe-card w-inline-block">
                    {recipe.Main_Image && recipe.Main_Image.formats && recipe.Main_Image.formats.small &&
                    <img src={`${recipe.Main_Image.formats.small.url}`} loading="lazy" alt="" className="recipe-category__recipe-card__image"></img>
                    }
                    {recipe.Main_Image && recipe.Main_Image.formats.small == null && 
                    <img src={`${recipe.Main_Image.url}`} loading="lazy" alt="" className="recipe-category__recipe-card__image"></img>
                    }
                    <div className="recipe-category__recipe-card__title">
                        {recipe.Food_Item_Name}
                    </div>
                </Link>
              )
            })}
               
            </div>
        </div>
        ))}
        <LogoutButton />
        </div>
    )
}
