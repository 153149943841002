import React, { useState } from "react";
import playbtn from '../playbtn.svg'
import closebtn from '../close.svg'

const VideoPopup = ({ videoURL }) => {
  // create state `open` with default as false
  const [open, setOpen] = useState(false);
  return (
    <>
      <div onClick={() => setOpen(!open)} className="recipe-content__youtubebutton"><img src={playbtn} loading="lazy" alt="" className="recipe-content__playbtn-svg" />
      {open && (
        <div className="recipe-content__youtube-video">
         <iframe width="100%" height="100%" src={videoURL} frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay={1} fs={0} modestbranding={1} rel={1} />
         <div className="recipe-content__video-close" onClick={() => setOpen(open)}><img src={closebtn} loading="lazy" alt="" className="recipe-content__video-close-img" /></div>
        </div>
      )}
    </div>
    </>
  );
};

export default VideoPopup;