import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import HttpsRedirect from 'react-https-redirect';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const t0 = performance.now();

function renderCallback(e) {
  console.log("Rendering done", e);
  const t1 = performance.now();
}

ReactDOM.render(
  <HttpsRedirect>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}>
      <App />
    </Auth0Provider>
  </HttpsRedirect>,
  document.getElementById('root'),
  renderCallback
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
