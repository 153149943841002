import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { useParams } from 'react-router-dom'
import VideoPopup from '../components/videopopup.js'

const RECIPE = gql`
  query GetRecipe($id: ID!) {
    recipe(id: $id) {
      id,
      Food_Item_Name,
      youtube_id,
      Main_Image {
        url,formats
      },
      category {
          Name
      },
      subcategory {
        Name
      },
    	Stats {Title, Description},
    	Ingredients{Name, Yield_1x, Yield_2x, Yield_3x, Image{formats}},
    	Procedure{Show_as_section, Section_title,Procedure_step{Step_image{formats, url},Step_Instructions}},
      Equipment_Items{Title, Image{formats}},
    }
  }
`
var stepCount = 1;

function addStep(x) {
  stepCount = x+1;
}

function resetStep() {
  stepCount = 1;
}

export default function Recipe() {

    const {id} = useParams()
    const { loading, error, data } = useQuery(RECIPE, {variables: {id:id}})
    
    if (loading) return <div className="recipe-content"></div>
    if (error) return <p>Error: Please contact the administrator</p>

    return (
        <div key={data.recipe.id} id="w-node-_588d8a5c-17c7-5cf3-3bff-5a3fb9dde7c1-9e3292e3" className="layout__contentframe">
          <div className="recipe-content">
            <div className="recipe-content__col1">
              <div className="recipe-content__padded-div">
                {data.recipe && data.recipe.youtube_id &&
                  <div>
                    {data.recipe && data.recipe.Main_Image && data.recipe.Main_Image.formats && data.recipe.Main_Image.formats.medium &&
                      <img src={`${data.recipe.Main_Image.formats.medium.url}`} loading="lazy" alt="" className="recipe-content__main-image" />
                    }{data.recipe && data.recipe.Main_Image && data.recipe.Main_Image.formats && data.recipe.Main_Image.formats.medium == null &&
                      <img src={`${data.recipe.Main_Image.url}`} loading="lazy" alt="" className="recipe-content__main-image" />
                    }
                    {data.recipe && data.recipe.youtube_id &&
                     <VideoPopup videoURL = {`https://www.youtube.com/embed/${data.recipe.youtube_id}?autoplay=1`}/>
                    }
                  </div>
                }
                {data.recipe && data.recipe.youtube_id == null &&
                  <div>
                    {data.recipe && data.recipe.Main_Image && data.recipe.Main_Image.formats && data.recipe.Main_Image.formats.medium &&
                      <img src={`${data.recipe.Main_Image.formats.medium.url}`} loading="lazy" alt="" className="recipe-content__main-image" />
                    }{data.recipe && data.recipe.Main_Image && data.recipe.Main_Image.formats && data.recipe.Main_Image.formats.medium == null &&
                      <img src={`${data.recipe.Main_Image.url}`} loading="lazy" alt="" className="recipe-content__main-image" />
                    }
                  </div>
                }
                {data.recipe && data.recipe.youtube_id == '' &&
                  <div>
                    {data.recipe && data.recipe.Main_Image && data.recipe.Main_Image.formats && data.recipe.Main_Image.formats.medium &&
                      <img src={`${data.recipe.Main_Image.formats.medium.url}`} loading="lazy" alt="" className="recipe-content__main-image" />
                    }{data.recipe && data.recipe.Main_Image && data.recipe.Main_Image.formats && data.recipe.Main_Image.formats.medium == null &&
                      <img src={`${data.recipe.Main_Image.url}`} loading="lazy" alt="" className="recipe-content__main-image" />
                    }
                  </div>
                }
                <div style={{display: 'inline-block'}}>{data.recipe && data.recipe.category && data.recipe.category.Name &&<div className="recipe-content__category">{data.recipe.category.Name}</div>}</div>
                <div style={{display: 'inline-block'}}>{data.recipe && data.recipe.subcategory && data.recipe.subcategory.Name &&<div className="recipe-content__subcategory">{data.recipe.subcategory.Name}</div>}</div>
                <div className="recipe-content__title">{data.recipe.Food_Item_Name}</div>
              </div>
              <div className="recipe-content__divider" />
              <div>
              {data && data.recipe && data.recipe.Stats && data.recipe.Stats != 0 &&
              <div className="recipe-content__stats">
                <div className="recipe-content__subtitle">Stats</div>
                <div className="w-layout-grid recipe-content__stats-table">
                {data.recipe.Stats.map(stats => (
                    <div key={stats.Title} className="recipe-content__stats-table__row">
                      <div id="w-node-aa2939d7-c2e9-dc87-b624-04257aa576d5-9e3292e3" className="recipe-content__stats-table__left">
                      <div><strong>{stats.Title}</strong></div>
                      </div>
                      <div id="w-node-dd9e7ec0-266f-3448-a157-0236dd591695-9e3292e3" className="recipe-content__stats-table__right">
                          <div>{stats.Description}</div>
                      </div>
                    </div>
                  ))}
                    
                </div>
              </div>
              }</div>
              <div className="recipe-content__divider"></div>
              <div>
              {data && data.recipe && data.recipe.Equipment_Items && data.recipe.Equipment_Items != 0 &&
              <div className="recipe-content__equipment">
                <div className="recipe-content__subtitle">Equipment</div>
                {data.recipe.Equipment_Items.map(equipment => (
                  <div key={equipment.Title} className="recipe-content__equipment-type">
                    {equipment && equipment.Image && equipment.Image.formats && equipment.Image.formats.thumbnail &&
                      <img src={`${equipment.Image.formats.thumbnail.url}`} loading="lazy" alt="" className="recipe-content__equipment-image" />
                    }
                    
                    <div className="recipe-content__equipment-text">{equipment.Title}</div>
                  </div>
                ))}
              </div>
              }
              <div className="recipe-content__divider"></div>
              </div>
              
            </div>
             
            <div className="recipe-content__col2">
              <div>
              {data && data.recipe && data.recipe.Ingredients && data.recipe.Ingredients != 0 &&
              <div className="recipe-content__ingredients">
                <div className="recipe-content__ingredients__header">
                  <div className="recipe-content__ingredients__heading-main">
                    <div><strong>Ingredients</strong></div>
                  </div>
                  <div className="recipe-content__ingredients__subheadings">
                    <div>1X</div>
                  </div>
                  <div className="recipe-content__ingredients__subheadings">
                    <div>2X</div>
                  </div>
                  <div className="recipe-content__ingredients__subheadings">
                    <div>3X</div>
                  </div>
                </div>
                {data.recipe.Ingredients.map(ingredient => (
                  <div key={ingredient.Name} className="recipe-content__ingredients-table-row">
                      <div className="recipe-content__ingredients-table__left">
                        <div>{ingredient.Image && ingredient.Image.formats && <img src={`${ingredient.Image.formats.thumbnail.url}`} loading="lazy" alt="" className="recipe-content__ingredients-image" />}</div>
                        <div className="recipe-content__ingredients-table__left-text"><strong>{ingredient.Name}</strong></div>
                      </div>
                      <div className="recipe-content__ingredients-table__right">
                      {ingredient.Yield_1x &&
                        <div>{ingredient.Yield_1x}</div>
                      }
                      </div>
                      <div className="recipe-content__ingredients-table__right">
                      {ingredient.Yield_2x &&
                        <div>{ingredient.Yield_2x}</div>
                      }
                      </div>
                      <div className="recipe-content__ingredients-table__right">
                      {ingredient.Yield_3x &&
                        <div>{ingredient.Yield_3x}</div>
                      }
                      </div>
                  </div>
                ))}
              </div>}</div>
              <div className="recipe-content__divider" />
              <div> {data.recipe && data.recipe.Procedure[0] && data.recipe.Procedure[0].Procedure_step[0] && 
              <div className="recipe-content__procedure">
                <div className="recipe-content__procedure__title">Procedure</div>
                {data.recipe.Procedure.map(procedure_section => (
                  <div>{procedure_section && procedure_section.Show_as_section == true &&
                  <div key={procedure_section.Section_title} className="recipe-content__procedure__section">
                    {procedure_section.Section_title && <div className="recipe-content__procedure__subheader">{procedure_section.Section_title}{resetStep()}</div>}
                    {procedure_section.Procedure_step.map(step => (
                      <div key={step.Step_Instructions} className="recipe-content__procedure__step">
                        <div className="recipe-content__prcedure__step__number">{stepCount}</div>
                        {step.Step_image && step.Step_image.formats && step.Step_image.formats.medium && <img src={`${step.Step_image.formats.medium.url}`} loading="lazy" alt="" className="recipe-content__procedure__step__image" />}
                        {step.Step_image && step.Step_image.formats && step.Step_image.formats.medium == null && <img src={`${step.Step_image.url}`} loading="lazy" alt="" className="recipe-content__procedure__step__image" />}
                        <div className="recipe-content__prcedure__step__body">{step.Step_Instructions}</div>
                        {addStep(stepCount)}
                      </div>
                    ))}
                  </div>}{procedure_section && procedure_section.Show_as_section !== true &&
                  <div key={procedure_section.Section_title}>
                    <div className="recipe-content__procedure__subheader">{resetStep()}</div>
                    {procedure_section.Procedure_step.map(step => (
                      <div key={step.Step_Instructions} className="recipe-content__procedure__step">
                        <div className="recipe-content__prcedure__step__number">{stepCount}</div>
                        {step.Step_image && step.Step_image.formats && step.Step_image.formats.medium && <img src={`${step.Step_image.formats.medium.url}`} loading="lazy" alt="" className="recipe-content__procedure__step__image" />}
                        {step.Step_image && step.Step_image.formats && step.Step_image.formats.medium == null && <img src={`${step.Step_image.url}`} loading="lazy" alt="" className="recipe-content__procedure__step__image" />}
                        <div className="recipe-content__prcedure__step__body">{step.Step_Instructions}</div>
                        {addStep(stepCount)}
                      </div>
                    ))}
                  </div>}</div>
                ))}
              </div>}</div>
            </div>
          </div>
    
      </div>
    )
    
}


