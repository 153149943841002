import React from 'react'
import ScriptTag from 'react-script-tag'

export default function footer() {
    return (
        <div data-w-id="ed764b96-9928-6749-0548-0ff94caf3b16" className="mobile-menu">
            <div className="mobile-menu__line"></div>
            <div className="mobile-menu__line"></div>
            <div className="mobile-menu__line"></div>
            <style dangerouslySetInnerHTML={{__html: "@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"fc530fa2-061f-e914-ba3a-46376da4b500\"] {-webkit-transform:translate3d(-100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(-100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(-100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(-100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);}}" }} />
            <ScriptTag src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60f98fd1b8e7d681b38541ac" type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossOrigin="anonymous" />
            <ScriptTag src="/js/webflow.js" type="text/javascript" />
        </div>
        
    )
}