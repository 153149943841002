import React from 'react'
import logo from '../logo.png'
import { Link } from 'react-router-dom'
import ScriptTag from 'react-script-tag'
import { useQuery as UseQuery, gql } from '@apollo/client'

const CATEGORIES = gql `
    query getCategorys {
        categorys(sort: "Sorting:asc") {
            Name,
            id
        }
    }
`

export default function mainPageMenu() {
    const { loading, error, data } = UseQuery (CATEGORIES)

    if (loading) return <div data-w-id="fc530fa2-061f-e914-ba3a-46376da4b500" className="layout__menu"></div>
    if (error) return <div data-w-id="fc530fa2-061f-e914-ba3a-46376da4b500" className="layout__menu"><p>Error: Please contact the administrator</p></div>

    return (
        <div data-w-id="fc530fa2-061f-e914-ba3a-46376da4b500" className="layout__menu">
        <div data-w-id="696cb992-b3df-56d7-b4f6-b2eaa41150c2" className="close" id="close">
        <div data-w-id="c9b1d34c-fe31-4eee-3da6-39f0b3d10a26" className="mobile-menu close">
          <div className="mobile-menu__line top" />
          <div className="mobile-menu__line bottom" />
        </div>
        <Link to={"../#logout"} className="menu__item menu__item--logo"><img src={logo} loading="lazy" alt="" /></Link>
        {data.categorys.map(category => (
            <Link key={category.Name} to={`..#${category.id}`} className="menu__item w-inline-block">
            <div>{category.Name}</div></Link>
            
        ))}
        </div>
        <ScriptTag src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60f98fd1b8e7d681b38541ac" type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossOrigin="anonymous" />
      <ScriptTag src="/js/webflow.js" type="text/javascript" />
      </div>
      
    )
}