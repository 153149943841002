import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"
import { useAuth0 } from "@auth0/auth0-react"

import Homepage from './pages/Homepage.js'
import Recipe from './pages/Recipe.js'
import Footer from './components/footer'
import MainPageMenu from './components/mainPageMenu'
import Login from './components/login.js'


// apollo client
const client = new ApolloClient({
  uri: 'https://chuck-recipes.herokuapp.com/graphql',
  cache: new InMemoryCache()
}) 

function App() {
  
  const {user, isAuthenticated} = useAuth0();

  return (
    <>
      {isAuthenticated == true && (
        <Router>
          <ApolloProvider client={client}>
          <div className="App">
          <div className="layout">
            <MainPageMenu />
            <Switch>
              <Route exact path="/">
                <Homepage />
              </Route>
              <Route path="/recipe/:id">
                <Recipe />
              </Route>
            </Switch>
          </div>
          </div>
          <Footer />
          </ApolloProvider>
        </Router>
        
    )}
    {isAuthenticated == false && (
      <Login />
    )}
  </>
  );
}

export default App;
